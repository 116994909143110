<template>
  <b-sidebar
    id="add-new-faq-sidebar"
    :visible="isAddNewFaqSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-faq-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ (!faqData.faq_id) ? 'Add New' : 'Edit' }} FAQ Content
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <template v-if="alert">
        <b-alert
          :variant="alertDetail.variant"
          class="m-1"
          show
        >
          <h4 class="alert-heading">
            {{ alertDetail.title }}
          </h4>
          <div
            v-if="alertDetail.variant === 'danger'"
            class="alert-body"
          >
            <ul class="pl-2">
              <li
                v-for="(item, index) in alertDetail.text"
                :key="index"
              >
                <template v-for="txt in item">
                  {{ txt }}
                </template>
              </li>
            </ul>
          </div>
          <div
            v-else
            class="alert-body"
          >
            {{ alertDetail.text }}
          </div>
        </b-alert>
      </template>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(registerUser)"
          @reset.prevent="resetForm"
        >

          <!-- Title -->
          <validation-provider
            #default="validationContext"
            name="Title"
            rules="required"
          >
            <b-form-group
              label="Title"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="faqData.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Title"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Message -->
          <validation-provider
            #default="validationContext"
            name="FAQ Content"
            rules="required"
          >
            <b-form-group
              label="FAQ Content"
              label-for="message"
            >
              <quill-editor
                id="message"
                ref="message"
                v-model="faqData.message"
                class="editor"
                :options="editorOption"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div
            v-if="faqData.attachments && faqData.attachments.length"
            class="my-75"
          >
            <div>
              {{ faqData.attachments.length }} Attachment{{ faqData.attachments.length > 1 ? 's' : '' }}
            </div>

            <div>
              <div
                v-for="(attachment, index) in faqData.attachments"
                :key="index"
                class="d-flex mb-25"
              >
                <b-link
                  :href="attachment.location"
                  class="flex-fill overflow-hidden"
                  target="_blank"
                >
                  <span class="text-muted align-text-top">{{ attachment.filename }}</span>
                  <span class="text-muted ml-25">({{ attachment.file_size }})</span>
                </b-link>
                <b-button
                  size="sm"
                  variant="light-danger"
                  @click="confirmDel(attachment, index)"
                >[x]</b-button>
              </div>
            </div>
          </div>

          <b-form-group
            label="Files"
            label-for="faq-file"
          >
            <b-form-file
              v-model="files"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              size="sm"
              multiple
            />
          </b-form-group>

          <!-- Tag Role -->
          <b-form-group
            label="Tags"
            label-for="faq-role"
          >
            <v-select
              v-model="faqData.tags"
              :options="roleOptions"
              :reduce="val => val.name"
              multiple
              taggable
              push-tags
              label="name"
              placeholder="Select tags"
              input-id="faq-tag"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-50"
              type="submit"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAlert, BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormFile,
  BLink,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormFile,
    BButton,
    BLink,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewFaqSidebarActive',
    event: 'update:is-add-new-faq-sidebar-active',
  },
  props: {
    isAddNewFaqSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    faqData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      files: [],
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean', 'link'],
          ],
        },
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  setup() {
    const toast = useToast()

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      toast,

      refFormObserver,
      getValidationState,
    }
  },
  methods: {
    resetForm() {
      this.$emit('reset-data')
    },
    registerUser() {
      let targetUrl = '/faq'
      if (this.faqData.faq_id) {
        targetUrl = '/faq/edit'
      }
      this.$http.post(targetUrl, this.faqData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .catch(error => {
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.errors !== '') ? error.response.data.errors : error,
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .then(resp => {
          if (resp.status === 200 && resp.data.success === true) {
            this.alert = false
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })

            const status = true

            const promise2 = new Promise(resolve => {
              if (this.files.length !== 0) {
                for (let i = 0; i < this.files.length; i += 1) {
                  const formFile = new FormData()
                  if (this.faqData.faq_id) {
                    formFile.append('faq_id', this.faqData.faq_id)
                  } else {
                    formFile.append('faq_id', resp.data.faq_id)
                  }
                  formFile.append('attachment', this.files[i])

                  this.$http.post('/faq/attach', formFile, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
                    },
                  })
                    .then(fresp => {
                      if (fresp.data.success === true) {
                        this.faqData.attachments.push(fresp.data.data)
                        resolve(true)
                      } else {
                        document.getElementById('loading-bg').style.display = 'none'

                        this.alert = true
                        this.alertDetail = {
                          variant: 'danger',
                          title: 'Error Saving Attachment Data',
                          text: (fresp.data.error !== '') ? fresp.data.error : '',
                        }
                        resolve(false)
                      }
                    })
                    .catch(error => {
                      document.getElementById('loading-bg').style.display = 'none'
                      this.alert = true
                      this.alertDetail = {
                        variant: 'danger',
                        title: 'Error Saving Attachment',
                        text: (error.response.data.errors !== '') ? error.response.data.errors : error,
                      }
                      resolve(false)
                    })
                }
              } else {
                resolve(true)
              }
            })

            Promise.all([promise2, status]).then(values => {
              if (values[0] && values[1]) {
                this.$emit('refetch-data')
                this.$emit('update:is-add-new-faq-sidebar-active', false)
              }
            })
          }
        })
    },
    confirmDel(data, index) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http.post('/faq/attach/delete', { attachment_id: data.attachment_id }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          })
            .then(resp => {
              if (resp.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your file has been deleted.',
                  showConfirmButton: false,
                })
                setTimeout(() => {
                  this.faqData.attachments.splice(index, 1)
                  this.$swal.close()
                }, 1000)
              }
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Deleting Data',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

#add-new-faq-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
